@import '../../includes/share';

.block-content {
  padding: 30px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  background-color: #ffffff;
}

.blog-post {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
  background-color: #fff;
  display: inline-block;
  width: 100%;
  border-radius: 3px;
  margin: 2em 0;

  &.single-post {
    margin: 0 0 2em 0;

    .post-content {
      padding: 2em 2.5em 0;
    }
  }

  &.blog-overview {
    background-color: transparent;
    box-shadow: none;

    .post-content {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
      background-color: #fff;
      border-radius: 3px;
      margin-bottom: 2em;
    }
  }
}

.suggested-post {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  .card-body {
    padding: 15px;
  }
}

.suggested-post-link {
  text-transform: none;
  font-weight: initial;

  &:hover {
    text-decoration: none;
    color: inherit;
  }

  .card.suggested-post {
    .suggested-post-image-container {
      min-height: 250px;
      max-height: 250px;
      overflow: hidden;

      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
      }
    }

    .card-body {
      h5 {
        min-height: 45px;
        text-transform: none;
      }
    }
  }
}

.post-content {
  h3 {
    margin-bottom: 0;
  }

  .blog-meta {
    margin: 0 0 15px 0;
    color: #333;
  }
}

.single-post {
  .post-content {
    h3 {
      text-transform: none;

      a {
        color: #333;
      }
    }

    a {
      &:hover {
        text-decoration: none;
      }
    }

    p {
      margin: 1em 0;
    }
  }
}

.blog-meta {
  list-style: none;
  padding: 0;
  margin: 5px 0 0;

  li {
    display: inline-block;
    color: #888;
  }
}

.blog-page .blog-post .post-content, .single-post .post-content {
  padding: 2em 2.5em;
}

.blog-image img {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}


.dashboard-list-box h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  padding: 25px 30px;
  color: #333333;
  background-color: #ffffff;
  display: block;
  border-bottom: 1px solid #eaeaea;
  border-radius: 4px 4px 0 0;
}

.dashboard-list-box h4.gray {
  background-color: #fbfbfb
}

.dashboard-list-box ul {
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #ffffff;
  border-radius: 0 0 4px 4px;
}

.dashboard-list-box ul li {
  padding: 23px 30px;
  border-bottom: 1px solid #eaeaea;
  transition: 0.3s;
  position: relative;
}

#route-to-blog-view .blogs .blog-page .post-content {

  .author {
    display: flex;
    align-items: center;

    &__image {
      display: inline-block;
      width: 2.188rem;
      height: 2.188rem;
      border: 2px solid #e6e6e6;
      border-radius: 100%;
      object-fit: cover;
      margin-right: .5rem;
    }

    &__name {
      display: inline-block;
      margin: 0;
    }
  }
}

// Media queries
@media (max-width: 768px) {
  .blog-page .blog-post .post-content, .single-post .blog-post-content {
    padding: 2em 1.5em;
  }

  .blog-post.single-post {
    margin-left: -15px !important;
    margin-right: -15px !important;
    border-radius: 0;
    width: calc(100% + 30px);

    h1 {
      font-size: 1.4em;
    }

    h2 {
      font-size: 1.2em;
    }

    h3 {
      font-size: 1em;
    }

    h4 {
      font-size: 0.8em;
    }
  }
}

@media (min-width: 768px) {
  .single-post {
    .blog-image {
      height: 450px;
      overflow: hidden;
      position: relative;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .post-content {
      top: -5em;
      width: 800px;
      max-width: 80%;
      margin: 0 auto;
      padding: 3em 4em 0;
      background: #fff;
      position: relative;
      border-radius: 5px;
    }
  }
}
