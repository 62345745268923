@import './assets/huurstunt/scss/utilities/common';

#route-to-blog-view {
  .blogs .blog-page {
    .single-post {
      @include boxed-widget--clean;
      overflow: hidden;
    }

    #inhoudsopgave {
      margin-bottom: 1rem;
      padding: 1.5rem;
      border: 1px solid $border-light-grey;
      background-color: #f9f9f9;
      border-radius: 0.5rem;
      h5 {
        color: $font-primary-color;
        font-size: 1.2rem;
      }
      ul {
        list-style: none;
        padding-left: 0;
        margin: 0;
        li:not(:last-child) {
          margin-bottom: 0.5rem;
        }
      }

    }
    .post-content {
      img {
        border-radius: 0.5rem;
        max-height: 360px;
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
      h2 {
        scroll-margin-top: $menu-height-desktop; //important so that nav does not hide h2 titles in blogposts when scroll snapping
      }
    }
  }

  .post-content .text-center {
    text-align: left !important;
    img {
      max-width: 100%;
    }
  }
}
